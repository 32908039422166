import React, { useCallback, useState } from "react";
import Navbar from "../common/Navbar";
import hero_image from "../../assets/images/png/hero_student_image.png";
import hero_image1 from "../../assets/images/png/hero_student_image1.png";
import green_bag from "../../assets/images/png/green_bag.png";
import pink_bag from "../../assets/images/png/pink_icon.png";
import icon from "../../assets/images/png/cart2_icon.png";
import skill_icon from "../../assets/images/png/skill_icon1.png";
import { Link } from "react-router-dom";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const Hero = () => {
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    qualification: "",
    course: "",
    nearestCenter: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setformData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const { name, email, phone, topic, qualification, course, nearestCenter } =
      formData;
    if (
      !name ||
      !email ||
      !phone ||
      !topic ||
      !qualification ||
      !course ||
      !nearestCenter
    ) {
      toast.error("All fields are required");
      return false;
    }
    if (
      qualification === " -- Select Qualification --" ||
      course === " -- Select Course --" ||
      nearestCenter === " -- Select Nearest Center --"
    ) {
      toast.error("Please select valid options for all dropdowns");
      return false;
    }
    return true;
  };

  const createForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const formdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      topic: formData.topic,
      qualification: formData.qualification,
      course: formData.course,
      nearestCenter: formData.nearestCenter,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
      setIsLoading(false);
    }
  };

  const createFormCallBack = useCallback((res) => {
    setIsLoading(false);
    console.log(res);
    if (res.data.status === "success") {
      // toast.success(res.data.message);
      alert(res.data.message);
      setformData({
        name: "",
        email: "",
        phone: "",
        topic: "",
        qualification: "",
        course: "",
        nearestCenter: "",
      });
    } else {
      // toast.error(res.data.message);
      alert(res.data.message);
    }
  });

  return (
    <div className="Hero_bg d-flex flex-column min-vh-100 justify-content-center">
      <ToastContainer />
      <div>
        <Navbar />
      </div>
      <div className="container mt-5 mb-5 mb-lg-0 d-flex flex-column justify-content-center flex-grow-1">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 position-relative">
            <img
              width={30}
              className="v position-absolute custom_hero_position_icon "
              src={skill_icon}
              alt="rounded_image"
            />
            <h1 className=" ff_heebo fs_40 fw-bold color_darkgray mb-1">
              Master Coding From Scratch
            </h1>
            <p className=" fs_17 ff_heebo color_darkgray fw-light mb-1 mt-md-2 mt-2">
              From our coding classes by experts near you
            </p>
            <p className=" fs_17 ff_heebo color_darkgray fw-light mb-2 ">
              A journey from ‘Beginners to Experts'
            </p>
            <h4 className="ff_heebo fs_25 fw-bold color_darkgray mb-1">
              Give a head start with the best IT & Software training institute
              for AI, Robotics & Coding !
            </h4>

            <div className="d-sm-flex align-items-center gap-3 mt-4">
              <Link to="/course">
                <div>
                  <button className="w-100 fs_15 px-4 ff_heebo fw-medium color_lightwhite Free_btn border-0 mt-sm-1">
                    Explore Online Coding Courses
                  </button>
                </div>
              </Link>
              <Link to="/centers">
                {" "}
                <button className="w-100 mt-3 mt-sm-0 fs_15 ff_heebo rounded-1 fw-medium color_lightwhite bg_darkblue px-4 py-2 border-0 mt-sm-1">
                  Explore our offline centers
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5 mt-4 mt-lg-0">
            <div className="bg_darkblue rounded-4 p-4">
              <h4 className="text-center color_yellow ff_heebo fs_29 fw-bolder ff_redrose">
                Book your Free Session
              </h4>
              <p className="text-center fs_18 ff_heebo color_lightwhite">
                Learn from Best IT Experts
              </p>
              <form action="" onSubmit={createForm}>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Name
                  </label>
                  <input
                    required
                    type="text"
                    className="py-2 px-3 mt-2"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Enter Your Mobile Number
                  </label>
                  <input
                    required
                    type="number"
                    className="py-2 px-3 mt-2"
                    placeholder="Enter Mobile Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    className="py-2 px-3 mt-2"
                    placeholder="Enter Email"
                    value={formData.email}
                    name="email"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    City
                  </label>
                  <input
                    required
                    type="text"
                    className="py-2 px-3 mt-2"
                    placeholder="Enter City"
                    value={formData.topic}
                    name="topic"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Nearest Center
                  </label>
                  <select
                    required
                    value={formData.nearestCenter}
                    name="nearestCenter"
                    onChange={handleInputChange}
                    className="py-2 px-3 mt-2"
                    id="nearestCenter"
                  >
                    <option value=" -- Select Nearest Center --">
                      -- Select Nearest Center --
                    </option>
                    <option value="Hisar">Hisar</option>
                    <option value="Rohtak">Rohtak</option>
                    <option value="Sirsa">Sirsa</option>
                    <option value="Sikar">Sikar</option>
                  </select>
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Qualification:
                  </label>
                  <select
                    required
                    value={formData.qualification}
                    name="qualification"
                    onChange={handleInputChange}
                    className="py-2 px-3 mt-2"
                    id=""
                  >
                    <option value=" -- Select Qualification --">
                      -- Select Qualification --
                    </option>
                    <option value="10th">10th</option>
                    <option value="11th">11th</option>
                    <option value="12th">12th</option>
                    <option value="Graduation">Graduation</option>
                    <option value="Post Graduation">Post Graduation</option>
                  </select>
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="text-white ff_heebo" htmlFor="Name">
                    Which course are you looking for?
                  </label>
                  <select
                    required
                    value={formData.course}
                    name="course"
                    onChange={handleInputChange}
                    className="py-2 px-3 mt-2"
                    id=""
                  >
                    <option value=" -- Select Course --">
                      -- Select Course --
                    </option>
                    <option value="Frontend Development">
                      Frontend Development
                    </option>
                    <option value="Backend Development">
                      Backend Development
                    </option>
                    <option value="FullStack Development">
                      FullStack Development
                    </option>
                    <option value="Web Development">Web Development</option>
                    <option value="Mobile App Development">
                      Mobile App Development
                    </option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="mt-5 bg_yellow ff_heebo fs_19 border-0 text-white w-100 py-2"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Request Callback"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
