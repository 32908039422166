// import React, { useCallback, useEffect, useState } from "react";
// import { CSVLink } from "react-csv"; // Import CSVLink
// import makeApiCall from "../callApi";

// const LeadsTab = () => {
//   const [leadsList, setLeadsList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [totalLeads, setTotalLeads] = useState(0);
//   const leadPerPage = 20;

//   async function getLead(page) {
//     await makeApiCall(
//       {
//         page: page,
//         limit: leadPerPage,
//       },
//       "post",
//       `admin/leads?limit=${leadPerPage}&page=${page}`,
//       leadsListCallback,
//       false
//     );
//   }

//   const leadsListCallback = useCallback((response) => {
//     if (response.status === 200) {
//       setLeadsList(response.data.data);
//       setTotalLeads(response.data.pagination.total);
//       setTotalPages(response.data.pagination.total_pages);
//       setCurrentPage(response.data.pagination.current_page);
//     } else {
//       console.log("Failed to fetch users");
//     }
//   }, []);

//   useEffect(() => {
//     getLead(currentPage);
//   }, [currentPage]);

//   const convertDate = (date) => {
//     const res = new Date(date).toDateString();
//     const res2 = new Date(date).toLocaleTimeString();
//     return { date: res, time: res2 };
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= totalPages) {
//       setCurrentPage(newPage);
//     }
//   };

//   // Prepare CSV data
//   const csvHeaders = [
//     { label: "No.", key: "no" },
//     { label: "Name", key: "name" },
//     { label: "Phone Number", key: "phone" },
//     { label: "City", key: "city" },
//     { label: "Nearest Center", key: "nearestCenter" },
//     { label: "Email", key: "email" },
//     { label: "Course", key: "course" },
//     { label: "Qualification", key: "qualification" },
//     { label: "Date/Time", key: "createdAt" },
//     { label: "BatchType", key: "batchType" },
//   ];

//   const csvData = leadsList.map((user, index) => {
//     const convertedDate = convertDate(user.createdAt);
//     return {
//       no: (currentPage - 1) * leadPerPage + index + 1,
//       name: user.name,
//       phone: user.phone,
//       city: user.topic,
//       nearestCenter: user.nearestCenter,
//       email: user.email,
//       course: user.course,
//       qualification: user.qualification,
//       createdAt: `${convertedDate.date} ${convertedDate.time}`,
//       batchType: user.batchType,
//     };
//   });

//   return (
//     <div>
//       <h2 className="fs_37 fw-semibold ff_heebo">Leads Data</h2>
//       <div className="border my-4"></div>
//       <div className="my-4 d-flex justify-content-end">
//         {/* CSVLink for downloading the CSV file */}
//         <CSVLink
//           data={csvData}
//           headers={csvHeaders}
//           filename={`leads_data_page_.csv`}
//           className="bg_yellow px-3 py-2 border-0 rounded-2 text-white fw-bold"
//         >
//           Export Excel File
//         </CSVLink>
//       </div>
//       <div className="border-0 ff_heebo pb-4">
//         <div
//           className="card rounded-3"
//           style={{ maxWidth: "1300px", overflowX: "auto" }}
//         >
//           <table className="w-100">
//             <thead>
//               <tr>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   No.
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Name
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Phone Number
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   City
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Nearest Center
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Email
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Course
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Qualification
//                 </th>
//                 <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
//                   Date/Time
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   BatchType
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {leadsList?.length === 0 ? (
//                 <tr>
//                   <td className="px-3 py-4" colSpan="10">
//                     No data found
//                   </td>
//                 </tr>
//               ) : (
//                 leadsList?.map((user, index) => {
//                   const convertedDate = convertDate(user.createdAt);
//                   return (
//                     <tr key={user._id}>
//                       <td className="px-3 py-3">
//                         {(currentPage - 1) * leadPerPage + index + 1}
//                       </td>
//                       <td className="px-3 py-3 text-nowrap">{user.name}</td>
//                       <td className="px-3 py-3 text-nowrap">{user.phone}</td>
//                       <td className="px-3 py-3 text-nowrap">{user.topic}</td>
//                       <td className="px-3 py-3 text-nowrap">
//                         {user.nearestCenter}
//                       </td>
//                       <td className="px-3 py-3 text-nowrap">{user.email}</td>
//                       <td className="px-3 py-3 text-nowrap">{user.course}</td>
//                       <td className="px-3 py-3 text-nowrap">
//                         {user.qualification}
//                       </td>
//                       <td className="px-3 py-3 text-nowrap">
//                         {convertedDate.date} {convertedDate.time}
//                       </td>
//                       <td className="px-3 py-3 text-nowrap">
//                         {user.batchType}
//                       </td>
//                     </tr>
//                   );
//                 })
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination d-flex align-items-center justify-content-end mt-4">
//           <button
//             className=" bg_yellow px-md-4 px-3 border-0 py-2 rounded-3 text-black me-2"
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//           >
//             Previous
//           </button>
//           <span className="mx-2 fw-bold">
//             Page {currentPage} of {totalPages}
//           </span>
//           <button
//             className="btn btn-primary px-md-4 px-3 ms-2"
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//           >
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LeadsTab;

import React, { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv"; // Import CSVLink
import makeApiCall from "../callApi";

const LeadsTab = () => {
  const [leadsList, setLeadsList] = useState([]);
  const [allLeads, setAllLeads] = useState([]); // State for all leads
  const [isDownloading, setIsDownloading] = useState(false); // To handle download state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);
  const leadPerPage = 20;

  async function getLead(page) {
    await makeApiCall(
      {
        page: page,
        limit: leadPerPage,
      },
      "post",
      `admin/leads?limit=${leadPerPage}&page=${page}`,
      leadsListCallback,
      false
    );
  }

  const leadsListCallback = useCallback((response) => {
    if (response.status === 200) {
      setLeadsList(response.data.data);
      setTotalLeads(response.data.pagination.total);
      setTotalPages(response.data.pagination.total_pages);
      setCurrentPage(response.data.pagination.current_page);
    } else {
      console.log("Failed to fetch users");
    }
  }, []);

  useEffect(() => {
    getLead(currentPage);
  }, [currentPage]);

  const convertDate = (date) => {
    const res = new Date(date).toDateString();
    const res2 = new Date(date).toLocaleTimeString();
    return { date: res, time: res2 };
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Function to fetch all leads data and trigger CSV download
  const fetchAndDownloadAllLeads = async () => {
    let allLeadsData = [];
    setIsDownloading(true); // Start downloading
    for (let page = 1; page <= totalPages; page++) {
      await makeApiCall(
        {
          page: page,
          limit: leadPerPage,
        },
        "post",
        `admin/leads?limit=${leadPerPage}&page=${page}`,
        (response) => {
          if (response.status === 200) {
            allLeadsData = [...allLeadsData, ...response.data.data];
          }
        },
        false
      );
    }
    setAllLeads(allLeadsData); // Set all leads data
    setIsDownloading(false); // End downloading
  };

  // Prepare CSV data for all leads
  const csvHeaders = [
    { label: "No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Phone Number", key: "phone" },
    { label: "City", key: "city" },
    { label: "Nearest Center", key: "nearestCenter" },
    { label: "Email", key: "email" },
    { label: "Course", key: "course" },
    { label: "Qualification", key: "qualification" },
    { label: "Date/Time", key: "createdAt" },
    { label: "BatchType", key: "batchType" },
  ];

  const csvData = allLeads.map((user, index) => {
    const convertedDate = convertDate(user.createdAt);
    return {
      no: index + 1,
      name: user.name,
      phone: user.phone,
      city: user.topic,
      nearestCenter: user.nearestCenter,
      email: user.email,
      course: user.course,
      qualification: user.qualification,
      createdAt: `${convertedDate.date} ${convertedDate.time}`,
      batchType: user.batchType,
    };
  });

  return (
    <div>
      <h2 className="fs_37 fw-semibold ff_heebo">Leads Data</h2>
      <div className="border my-4"></div>
      <div className="my-4 d-flex gap-4 justify-content-end">
        {/* Combined button for fetching all leads and downloading */}
        <button
          onClick={async () => {
            await fetchAndDownloadAllLeads(); // Fetch all leads
            if (allLeads.length > 0) {
              // Trigger download if leads are fetched
              document.getElementById("csvLink").click(); // Programmatically trigger CSV download
            }
          }}
          className="bg_yellow px-3 py-2 border-0 rounded-2 text-white fw-bold"
          disabled={isDownloading}
        >
          {isDownloading ? "Preparing..." : "Download All Leads"}
        </button>

        {/* CSVLink for downloading the CSV file, hidden but available for programmatic click */}
        <CSVLink
          id="csvLink"
          data={csvData}
          headers={csvHeaders}
          filename={`all_leads_data.csv`}
          className="d-none" // Hide CSVLink button
        />
      </div>

      <div className="border-0 ff_heebo pb-4">
        <div
          className="card rounded-3"
          style={{ maxWidth: "1300px", overflowX: "auto" }}
        >
          <table className="w-100">
            <thead>
              <tr>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  No.
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Name
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Phone Number
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  City
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Nearest Center
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Email
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Course
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Qualification
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Date/Time
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  BatchType
                </th>
              </tr>
            </thead>
            <tbody>
              {leadsList && leadsList.length > 0 ? (
                leadsList.map((user, index) => {
                  const convertedDate = convertDate(user.createdAt);
                  return (
                    <tr key={user._id}>
                      <td className="px-3 py-3">
                        {(currentPage - 1) * leadPerPage + index + 1}
                      </td>
                      <td className="px-3 py-3 text-nowrap">{user.name}</td>
                      <td className="px-3 py-3 text-nowrap">{user.phone}</td>
                      <td className="px-3 py-3 text-nowrap">{user.topic}</td>
                      <td className="px-3 py-3 text-nowrap">
                        {user.nearestCenter}
                      </td>
                      <td className="px-3 py-3 text-nowrap">{user.email}</td>
                      <td className="px-3 py-3 text-nowrap">{user.course}</td>
                      <td className="px-3 py-3 text-nowrap">
                        {user.qualification}
                      </td>
                      <td className="px-3 py-3 text-nowrap">
                        {convertedDate.date} {convertedDate.time}
                      </td>
                      <td className="px-3 py-3 text-nowrap">
                        {user.batchType}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="px-3 py-4" colSpan="10">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination d-flex align-items-center justify-content-end mt-4">
        <button
          className="bg_yellow px-md-4 px-3 border-0 py-2 rounded-3 text-black me-2"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="mx-2 fw-bold">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-primary px-md-4 px-3 ms-2"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeadsTab;
