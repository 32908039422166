// import React, { useCallback, useEffect, useState } from "react";
// import makeApiCall from "../callApi";

// const UserTab = () => {
//   const [userList, setUserList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);

//   const usersPerPage = 10;

//   async function getUser(page) {
//     await makeApiCall(
//       {
//         page: page,
//         limit: usersPerPage,
//       },
//       "post",
//       "admin/users",
//       userListCallback,
//       false
//     );
//   }

//   const userListCallback = useCallback((response) => {
//     console.log(response);
//     if (response.status === 200) {
//       setUserList(response.data.data);
//       setTotalUsers(response.data.total);
//       // setTotalPages(Math.ceil(response.data.total / usersPerPage));
//       setLimit(response.data.limit);
//       setPage(response.data.page);
//       console.log(response.data);
//     } else {
//       console.log("Failed to fetch users");
//     }
//   }, []);

//   useEffect(() => {
//     getUser(currentPage);
//   }, [currentPage]);

//   const handlePageChange = (page) => {
//     if (page >= 1 && page <= totalPages) {
//       setCurrentPage(page);
//     }
//   };

//   return (
//     <div>
//       <h2 className="fs_37 fw-semibold ff_heebo">Users Data</h2>
//       <div className="border my-4"></div>
//       <div className="border-0 ff_heebo pb-4">
//         <div
//           className="card rounded-3"
//           style={{ maxWidth: "1300px", overflowX: "auto" }}
//         >
//           <table className="w-100">
//             <thead>
//               <tr>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   No.
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   Name
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   Phone Number
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   City
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   Course
//                 </th>
//                 <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
//                   Qualification
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {userList.length === 0 ? (
//                 <tr>
//                   <td className="px-3 py-4" colSpan="5">
//                     No data found
//                   </td>
//                 </tr>
//               ) : (
//                 userList.map((user, index) => (
//                   <tr key={user.id}>
//                     <td className="px-3 py-3">
//                       {(currentPage - 1) * usersPerPage + index + 1}
//                     </td>
//                     <td className="px-3 py-3">{user.name}</td>
//                     <td className="px-3 py-3">{user.phone}</td>
//                     <td className="px-3 py-3">{user.city}</td>
//                     <td className="px-3 py-3">{user.course}</td>
//                     <td className="px-3 py-3">{user.qualification}</td>
//                     {/* Add edit and delete functionality */}
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//           {/* <div className="pagination">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               Previous
//             </button>
//             <span>
//               Page {currentPage} of {totalPages}
//             </span>
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               Next
//             </button>
//           </div>
//           <div className="pagination-info">
//             <p>Showing {limit} users per page</p>
//             <p>Current Page: {page}</p>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserTab;

import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";

const UserTab = () => {
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const usersPerPage = 20;

  async function getUser(page) {
    await makeApiCall(
      {
        page: page,
        limit: usersPerPage,
      },
      "post",
      "admin/users",
      userListCallback,
      false
    );
  }

  const userListCallback = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      setUserList(response.data.data);
      setLimit(response.data.pagination.per_page);
      setCurrentPage(response.data.pagination.current_page);
      setTotalPages(response.data.pagination.total_pages);
      console.log(response.data);
    } else {
      console.log("Failed to fetch users");
    }
  }, []);

  useEffect(() => {
    getUser(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      <h2 className="fs_37 fw-semibold ff_heebo">Users Data</h2>
      <div className="border my-4"></div>
      <div className="border-0 ff_heebo pb-4">
        <div
          className="card rounded-3"
          style={{ maxWidth: "1300px", overflowX: "auto" }}
        >
          <table className="w-100">
            <thead>
              <tr>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  No.
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Name
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Phone Number
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  City
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Course
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Qualification
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.length === 0 ? (
                <tr>
                  <td className="px-3 py-4" colSpan="6">
                    No data found
                  </td>
                </tr>
              ) : (
                userList.map((user, index) => (
                  <tr key={user._id}>
                    <td className="px-3 py-3">
                      {(currentPage - 1) * usersPerPage + index + 1}
                    </td>
                    <td className="px-3 py-3">{user.name}</td>
                    <td className="px-3 py-3">{user.phone}</td>
                    <td className="px-3 py-3">{user.city}</td>
                    <td className="px-3 py-3">{user.course}</td>
                    <td className="px-3 py-3">{user.qualification}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* <div className="pagination-info mt-2">
            <p>Showing {limit} users per page</p>
          </div> */}
        </div>
        <div className="pagination d-flex align-items-center justify-content-end mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-primary me-2"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="btn btn-primary ms-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTab;
