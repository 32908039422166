import React from "react";
import Navbar from "../common/Navbar";
import timeline_image from "../../assets/images/png/timeline_image.png";
import icon1 from "../../assets/images/png/icon1.png";
import roadmap_image from "../../assets/images/png/roadmap_mobile_image.png";

const AboutHero = () => {
  return (
    <div className=" overflow-X-hidden">
      <Navbar />
      <div className="container ">
        <div className=" d-xl-block d-none position-relative">
          <img className="w-100 " src={timeline_image} alt="timeline_image" />
          <div>
            <div className=" position-absolute timeline_box1">
              <h4 className=" fs_43 fw-bold ff_heebo color_yellow mb-0">
                OUR JOURNEY{" "}
              </h4>
              <h5 className=" fa_26 mt-1 fw-semibold ff_heebo color_lightblack">
                It has been an  <br></br>amazing ride! 2021
              </h5>
            </div>
            <div className=" text-end custom_max_width_timeline_box2">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Jan 2020
                </h4>
                <strong className="ff_heebo">
                  KODU started its Online Platform
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  We have started our Kodu journey with 10 students from our
                  first batch in lockdown from our Online Learning Program’s
                </p>
              </div>
            </div>
            <div className=" text-end custom_max_width_timeline_box3">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Jan 2021
                </h4>
                <strong className="ff_heebo">
                  We crossed a Milestone of 1500+Students
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  By the starting of 2021 , we had over 1500+ enrolments in our
                  courses foreseeing our quality content & job demand in IT
                  industry , as everyone was going online.
                </p>
              </div>
            </div>
            <div className=" custom_max_width_timeline_box4">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box3 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Jan 2022
                </h4>
                <strong className="ff_heebo">Launched over 15+ Courses</strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  With over 4000+ enrolments , 70+ Trainers , we have launched
                  over 15+ different online courses in every segment.
                </p>
              </div>
            </div>
            <div className=" custom_max_width_timeline_box5">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box3 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Jan 2023
                </h4>
                <strong className="ff_heebo">
                  1st Offline Branch in Hisar
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  Our 1st Offline Classroom Branch in Hisar April 2023
                </p>
              </div>
            </div>
            <div className=" text-end custom_max_width_timeline_box6">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  April 2023
                </h4>
                <strong className="ff_heebo">
                  2nd Offline Branch in Rohtak
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  Our 2nd Offline Classroom Branch in Rohtak
                </p>
              </div>
            </div>
            <div className=" text-end custom_max_width_timeline_box7">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  June 2023
                </h4>
                <strong className="ff_heebo">
                  3rd Offline Branch in Sirsa
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  Our 3rd Offline Classroom Branch in Sirsa
                </p>
              </div>
            </div>
            <div className=" custom_max_width_timeline_box8">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Nov 2023
                </h4>
                <strong className="ff_heebo">
                  4th Offline Branch in Sikar
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  Our 4th Offline Classroom Branch in Sikar
                </p>
              </div>
            </div>
            <div className=" custom_max_width_timeline_box9">
              <img width={50} src={icon1} alt="icon1" />
              <div className="timeline_box2 p-3 mt-2">
                <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                  Jan 2024
                </h4>
                <strong className="ff_heebo">
                  5th Offline Branch in Jaipur
                </strong>
                <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                  Our 5th Offline Classroom Branch in Jaipur From your
                  additional information, it seems your coding institute started
                  with only 10 students in 2021 but has grown to over 5,000
                  learners by now, with offline branches in several cities. This
                  signifies a remarkable growth trajectory, reflecting the
                  increasing demand for programming courses in the IT industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 mb-5 mb-xl-0 d-xl-none d-block">
          <div className=" mb-4">
            <h4 className=" fs_43 fw-bold ff_heebo color_blue mb-0">
              OUR JOURNEY{" "}
            </h4>
            <h5 className=" fa_26 mt-1 fw-semibold ff_heebo color_lightblack">
              It has been an  <br></br>amazing ride! 2021
            </h5>
          </div>
          <div className="d-flex gap-sm-5 gap-3">
            <div>
              <img
                width={30}
                className="h-100"
                src={roadmap_image}
                alt="roadmap_image"
              />
            </div>
            <div>
              <div>
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Jan 2020
                  </h4>
                  <strong className="ff_heebo">
                    KODU started its Online Platform
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    We have started our Kodu journey with 10 students from our
                    first batch in lockdown from our Online Learning Program’s
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Jan 2021
                  </h4>
                  <strong className="ff_heebo">
                    We crossed a Milestone of 1500+Students
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    By the starting of 2021 , we had over 1500+ enrolments in
                    our courses foreseeing our quality content & job demand in
                    IT industry , as everyone was going online.
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Jan 2022
                  </h4>
                  <strong className="ff_heebo">
                    Launched over 15+ Courses
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    With over 4000+ enrolments , 70+ Trainers , we have launched
                    over 15+ different online courses in every segment.
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Jan 2023
                  </h4>
                  <strong className="ff_heebo">
                    1st Offline Branch in Hisar
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    Our 1st Offline Classroom Branch in Hisar April 2023
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    April 2023
                  </h4>
                  <strong className="ff_heebo">
                    2nd Offline Branch in Rohtak
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    Our 2nd Offline Classroom Branch in Rohtak
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    June 2023
                  </h4>
                  <strong className="ff_heebo">
                    3rd Offline Branch in Sirsa
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    Our 3rd Offline Classroom Branch in Sirsa
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Nov 2023
                  </h4>
                  <strong className="ff_heebo">
                    4th Offline Branch in Sikar
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    Our 4th Offline Classroom Branch in Sikar
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <img width={50} src={icon1} alt="icon1" />
                <div className="timeline_box2 p-3 mt-2">
                  <h4 className=" fs_29 fw-bold ff_heebo color_blue mb-0">
                    Jan 2024
                  </h4>
                  <strong className="ff_heebo">
                    5th Offline Branch in Jaipur
                  </strong>
                  <p className=" fa_26 mt-1 ff_heebo color_lightblack">
                    Our 5th Offline Classroom Branch in Jaipur From your
                    additional information, it seems your coding institute
                    started with only 10 students in 2021 but has grown to over
                    5,000 learners by now, with offline branches in several
                    cities. This signifies a remarkable growth trajectory,
                    reflecting the increasing demand for programming courses in
                    the IT industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
